<template>
  <v-card
    :to="{ name: 'Registration', params: { id: event.id } }"
    class="my-4"
    tile
  >
    <v-container>
      <v-sheet class="pl-2">
        <div class="title mb-1">{{ event.name }}</div>

        <div
          v-if="event.start === event.end"
          class="d-flex align-center mb-1 ml-2"
        >
          <v-icon small left>mdi-calendar</v-icon>
          <span class="subtitle grey--text text--darken-1 mr-1">{{
            $d(new Date(event.start * 1000), "longDate")
          }}</span>
        </div>

        <div v-else class="d-flex align-center mb-1 ml-2">
          <v-icon small left>mdi-calendar</v-icon>
          <span class="subtitle grey--text text--darken-1 mr-1">{{
            $d(new Date(event.start * 1000), "longDateWithoutYear")
          }}</span>
          <span class="subtitle grey--text text--darken-1 mr-1 my-1">-</span>
          <span
            v-if="event.start !== event.end"
            class="subtitle grey--text text--darken-1 mr-1"
            >{{ $d(new Date(event.end * 1000), "longDate") }}</span
          >
        </div>

        <div class="d-flex align-center mb-8 ml-2">
          <v-icon small left>mdi-map-marker</v-icon>
          <span class="subtitle grey--text text--darken-1 mr-1">{{
            event.place
          }}</span>

          <CountryFlag
            :country="getItemById(event.country)"
            :size="15"
          ></CountryFlag>
        </div>

        <div class="mb-2">
          <EventStateChip :state="event.state"></EventStateChip>
        </div>
      </v-sheet>

      <svg
        v-if="event.imageUrl"
        width="60%"
        height="100%"
        class="backgroundImage"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="0" stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" stop-opacity="1" />
          </linearGradient>

          <mask id="gradient-mask">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#gradient)"
            />
          </mask>
        </defs>

        <image
          width="100%"
          height="100%"
          y="0"
          x="0"
          preserveAspectRatio="xMaxYMax slice"
          :href="`${baseURL}/${event.imageUrl}`"
          mask="url(#gradient-mask)"
        />
      </svg>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapGetters } from "vuex";
import EventStateChip from "@/components/EventStateChip";
import CountryFlag from "@/components/CountryFlag";

export default {
  name: "EventItem",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: {
    EventStateChip,
    CountryFlag
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    ...mapGetters({
      getItemById: "nations/getItemById"
    }),
    hasImage() {
      return this.event.imageUrl ? true : false;
    }
  }
};
</script>

<style scoped>
.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
