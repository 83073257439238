<template>
  <v-main>
    <v-carousel
      :show-arrows="false"
      hide-delimiter-background
      cycle
      interval="10000"
    >
      <v-carousel-item
        v-for="slide in slides"
        :key="slide.alt"
        :src="slide.src"
        :alt="slide.alt"
        eager
      >
        <v-sheet
          tile
          class="gray-fade-right pa-8 d-flex align-center white--text"
          :max-width="overlayWidth"
          height="100%"
        >
          <div>
            <div class="headline">ALGE-Results</div>
            <div class="subtitle-1 mb-6">{{ slide.text }}</div>
            <div class="overline">
              {{ $t("slider.poweredBy") }}
              <img
                :src="require('../assets/img/alge_timing_logo.svg')"
                alt="ALGE-TIMING"
                class="logo"
              />
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card tile>
                  <v-card-title>{{ $t("home.featuredEvents") }}</v-card-title>

                  <v-text-field
                    name="search"
                    :placeholder="$t('home.search')"
                    v-model="eventsForm.search"
                    clearable
                    class="mx-4"
                    @input="eventsSearchInput"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card v-if="!events || events.length === 0" class="my-4" tile>
                  <v-card-text>{{ $t("home.curNoEventsFound") }}</v-card-text>
                </v-card>

                <EventItem
                  v-for="event in filteredEvents"
                  :key="event.id"
                  :event="event"
                />
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col cols="6" sm="4" md="3">
                <v-btn
                  v-if="events && events.length > 0"
                  color="primary"
                  :disabled="!hasPrevEventPage"
                  width="100%"
                  @click="prevEventPage"
                >
                  <v-icon large>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="6" sm="4" md="3">
                <v-btn
                  v-if="events && events.length > 0"
                  color="primary"
                  :disabled="!hasNextEventPage"
                  width="100%"
                  @click="nextEventPage"
                >
                  <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="12" md="6">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card tile>
                  <v-card-title>{{ $t("home.registrations") }}</v-card-title>

                  <v-text-field
                    name="search"
                    :placeholder="$t('home.search')"
                    v-model="registrationsForm.search"
                    clearable
                    class="mx-4"
                    @input="registrationsSearchInput"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card
                  v-if="!registrations || registrations.length === 0"
                  class="my-4"
                  tile
                >
                  <v-card-text>{{
                    $t("home.curNoRegistrationsFound")
                  }}</v-card-text>
                </v-card>

                <RegistrationItem
                  v-for="registration in filteredRegistrations"
                  :key="registration.id"
                  :event="registration"
                />
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col cols="6" sm="4" md="3">
                <v-btn
                  v-if="events && events.length > 0"
                  color="primary"
                  :disabled="!hasPrevRegistrationPage"
                  width="100%"
                  @click="prevRegistrationPage"
                >
                  <v-icon large>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="6" sm="4" md="3">
                <v-btn
                  v-if="events && events.length > 0"
                  color="primary"
                  :disabled="!hasNextRegistrationPage"
                  width="100%"
                  @click="nextRegistrationPage"
                >
                  <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";
import EventItem from "@/components/EventItem.vue";
import RegistrationItem from "@/components/RegistrationItem.vue";

export default {
  name: "Home",
  components: {
    EventItem,
    RegistrationItem
  },
  data() {
    return {
      slides: [
        {
          alt: "Bike Hill Race",
          src: require("../assets/img/bikehill_race.jpg"),
          text: this.$i18n.t("slider.slide1")
        },
        {
          alt: "Downhill Race",
          src: require("../assets/img/downhill_race.jpg"),
          text: this.$i18n.t("slider.slide2")
        },
        {
          alt: "Ski Race",
          src: require("../assets/img/ski_race.jpg"),
          text: this.$i18n.t("slider.slide3")
        }
      ],
      eventsForm: {
        loading: true,
        search: null,
        currentPage: 0
      },
      registrationsForm: {
        loading: true,
        search: null,
        currentPage: 0
      },
      itemsPerPage: 5
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/`
        }
      ]
    };
  },
  computed: {
    ...mapState({
      events: state => state.events.items,
      registrations: state => state.events.openItems
    }),
    filteredEvents() {
      return this.events ? this.events.slice(0, this.itemsPerPage) : null;
    },
    filteredRegistrations() {
      return this.registrations
        ? this.registrations.slice(0, this.itemsPerPage)
        : null;
    },
    hasPrevEventPage() {
      return this.eventsForm.currentPage !== 0;
    },
    hasNextEventPage() {
      return this.itemsPerPage < this.events.length;
    },
    hasPrevRegistrationPage() {
      return this.registrationsForm.currentPage !== 0;
    },
    hasNextRegistrationPage() {
      return this.itemsPerPage < this.registrations.length;
    },
    overlayWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        default:
          return "50%";
      }
    }
  },
  methods: {
    ...mapActions({
      fetchEvents: "events/fetchItems",
      fetchRegistrations: "events/fetchOpenItems"
    }),
    prevEventPage() {
      this.eventsForm.currentPage -= 1;
      this.fetchEventPage(this.eventsForm.search ? this.eventsForm.search : "");
    },
    nextEventPage() {
      this.eventsForm.currentPage += 1;
      this.fetchEventPage(this.eventsForm.search ? this.eventsForm.search : "");
    },
    prevRegistrationPage() {
      this.registrationsForm.currentPage -= 1;
      this.fetchRegistrationPage(
        this.registrationsForm.search ? this.registrationsForm.search : ""
      );
    },
    nextRegistrationPage() {
      this.registrationsForm.currentPage += 1;
      this.fetchRegistrationPage(
        this.registrationsForm.search ? this.registrationsForm.search : ""
      );
    },
    eventsSearchInput: _.debounce(function(value) {
      this.eventsForm.currentPage = 0;
      this.fetchEventPage(value ? value : "");
    }, 250),
    registrationsSearchInput: _.debounce(function(value) {
      this.registrationsForm.currentPage = 0;
      this.fetchRegistrationPage(value ? value : "");
    }, 250),
    fetchEventPage(search = "") {
      const filter = {
        offset: this.itemsPerPage * this.eventsForm.currentPage,
        limit: this.itemsPerPage + 1
      };

      if (search.length === 0) {
        filter.maxDuration = 10;
        filter.maxEnd = moment()
          .add(14, "days")
          .unix();
      } else {
        filter.search = search;
      }

      this.fetchEvents(filter)
        .then(() => {
          this.eventsForm.loading = false;
        })
        .catch(() => {
          this.eventsForm.loading = false;
        });
    },
    fetchRegistrationPage(search = "") {
      const filter = {
        offset: this.itemsPerPage * this.registrationsForm.currentPage,
        limit: this.itemsPerPage + 1
      };

      if (search.length === 0) {
        filter.maxDuration = 10;
      } else {
        filter.search = search;
      }

      this.fetchRegistrations(filter)
        .then(() => {
          this.registrationsForm.loading = false;
        })
        .catch(() => {
          this.registrationsForm.loading = false;
        });
    }
  },
  created() {
    this.fetchEventPage();
    this.fetchRegistrationPage();
  }
};
</script>

<style scoped>
.gray-fade-right {
  background: linear-gradient(
    90deg,
    rgba(50, 50, 50, 0.9) 0%,
    rgba(50, 50, 50, 0) 100%
  );
}
.logo {
  height: auto;
  width: 80px;
}
</style>
